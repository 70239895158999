<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Supplier"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadSuppliers()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in fields"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModal"
      centered
      @hide="closeModal()"
      size="lg"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-group label="Supplier ID" label-for="v-name">
            <b-form-input v-model="formData.supplier_id"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Supplier Name" label-for="v-name">
            <b-form-input v-model="formData.name"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2>Address</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Building/Block/House No. and Street Name"
            label-for="v-addr1"
          >
            <b-input-group class="input-group-merge">
              <b-form-input v-model="formData.addr1" id="v-addr1" />
              <b-input-group-append
                is-text
                @click="isShowingMap = !isShowingMap"
              >
                <feather-icon class="pin" icon="MapPinIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Unit No. and Building Name" label-for="v-addr2">
            <b-form-input v-model="formData.addr2" id="v-addr2" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <ModalMap
            :showMap="isShowingMap"
            @onChangePlace="(v) => onGetPlace(v)"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Country" label-for="v-company_name">
            <v-select
              v-model="formData.country"
              :options="$store.getters['hbaseGeneral/getAllCountries']"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Postal" label-for="v-postal">
            <b-form-input v-model="formData.postal" id="v-postal" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2>Contacts</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="Telephone" label-for="v-name">
            <b-form-input v-model="formData.tel"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="Fax" label-for="v-name">
            <b-form-input v-model="formData.fax"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Email" label-for="v-name">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                maxlength="60"
                v-model="formData.email"
                :state="errors.length > 0 ? false : null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Website" label-for="v-name">
            <b-form-input v-model="formData.website"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="PTC Name" label-for="v-name">
            <b-form-input v-model="formData.ptc_name"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="closeModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import suppliersApi from '@api/suppliers';
import { queryParameters } from '@/schema';

export default {
  name: 'SuppliersPage',
  components: {},
  data: () => ({
    totalCount: 0,
    page: 0,
    size: 0,
    isShowingMap: false,
    showModal: false,
    dataSource: [],
    fields: [
      { caption: 'Supplier ID', field: 'supplier_id' },
      { caption: 'Name', field: 'name' },
    ],
    formData: {},
  }),
  watch: {
    page(v) {
      this.loadSuppliers();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadSuppliers();
      }
    },
  },
  mounted() {
    this.loadSuppliers();
  },
  methods: {
    loadSuppliers() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      suppliersApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;
            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      let app;
      if (this.formData.id) {
        app = suppliersApi.update(this.formData);
      } else {
        app = suppliersApi.add(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.loadSuppliers();
            this.closeModal();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    onOpenModal(event) {
      this.showModal = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      this.$nextTick(() => {
        this.formData = Object.assign({}, data);
      });
    },
    onDelete(event) {
      suppliersApi
        .delete(event)
        .then(() => {
          this.loadSuppliers();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onGetPlace(v) {
      //
    },
    closeModal() {
      this.showModal = false;

      this.$nextTick(() => {
        this.formData = {};
      });
    },
  },
};
</script>

<style></style>
